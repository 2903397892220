<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-body pt-6">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
            <span
              >{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :rows-per-page="50"
          empty-table-text="Aucune offre à afficher."
          :key="state.loadingDatatable"
          class="table-small-td"
        >
          <template v-slot:cell-loc_nature="{ row: data }">
            <div
              v-if="data.loc_nature == 'A'"
              class="badge w-100px badge-light-dark bt-5"
            >
              {{$t("Abonnement")}}
            </div>
            <div v-else class="badge badge-light-dark w-100px bt-5">
              {{$t("Support")}}
            </div>
          </template>
          <template v-slot:cell-loc_designation="{ row: data }">
            <div class="text-gray-800">
              <div class="text-gray-400">
                <span class="w-150px fs-8">{{ data.loc_reference }}</span>
              </div>
              {{ data.loc_designation }}
            </div>
          </template>
          <template v-slot:cell-cnt_num_contrat="{ row: data }">
            <span class="text-primary">{{ data.cnt_num_contrat }} </span>
          </template>
          <template v-slot:cell-cnt_debut="{ row: data }">
            <span v-if="!data.cnt_debut">
              <div class="badge badge-light-danger bt-5 text-danger">
                {{$t("En attente")}}
              </div>
            </span>
            <span v-else>
              <div class="badge badge-light-dark w-100px bt-7">
                {{$t("DU")}} {{ formatDate(data.cnt_debut) }}
              </div>
              <br />
              <div class="badge badge-light-dark w-100px bt-5">
                {{$t("AU")}} {{ formatDate(data.cnt_fin) }}
              </div>
            </span>
          </template>
          <template v-slot:cell-cnt_smart_account="{ row: data }">
            <span class="w-150px fs-8" v-if="data.loc_nature == 'A'">
              <span class="bullet bullet-dot bg-primary"></span>
              <b class="text-primary"> {{$t("SmartAccount:")}}</b>
              &nbsp;{{ data.cnt_smart_account }}
            </span>
            <span class="w-150px fs-8" v-else v-html="data.ccoid"></span>
          </template>
          <template v-slot:cell-cnt_status="{ row: data }">
            <span
              class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark"
              style="cursor: default"
              v-if="data.cnt_status == 'Traité / Conforme'"
            >
              <span class="svg-icon svg-icon-1 svg-icon-success">
                <inline-svg src="media/icons/duotune/arrows/arr085.svg" />
              </span>
              {{$t("Traité")}}
            </span>
            <span
              class="me-2 mb-2 btn bg-light-warning btn-sm btn-text-dark"
              style="cursor: default"
              v-else
            >
              {{$t("En cours de traitement")}}
            </span>
          </template>
          <template v-slot:cell-sn_tot="{ row: data }">
            <a
              :test="data"
              class="
                btn btn-sm btn-active-primary
                mt-2
                mb-2
                btn-bg-white btn-text-dark
              "
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              v-if="data.sn_tot > 0"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/general/gen004.svg" />
                {{$t("Equipements liés")}}
              </span>
            </a>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Détail d'une commande | Livraisons",
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
    });

    const tableHeader = ref([
      {
        name: "Type",
        key: "loc_nature",
        sortable: true,
      },
      {
        name: "Référence / Désignation",
        key: "loc_designation",
        sortable: true,
      },
      {
        name: "N° Contrat",
        key: "cnt_num_contrat",
        sortable: true,
      },
      {
        name: "Dates",
        key: "cnt_debut",
        sortable: true,
      },
      {
        name: "CCO / Smart Account",
        key: "cnt_smart_account",
        sortable: true,
      },
      {
        name: "Statut",
        key: "cnt_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "sn_tot",
        sortable: true,
      },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };
    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == "Offre validée") return "btn-icon-success";
      if (status == "Offre déclinée") return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == "Offre validée")
        return "media/icons/duotune/general/gen048.svg";
      if (status == "Offre déclinée")
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(
        "Détail d'une commande | Support et abonnements",
        ["Home", "Board Stillnetwork"]
      );

      const codeToc = router.currentRoute.value.params.code as string;
      let myListLiv = await mAxiosApi.prototype.getAxios("/getSupport/" + codeToc);
      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
    };
  },
});
</script>
